import { useIntl } from 'react-intl'

import { ProgramDescription, ProgramInformations } from '../../components/sections'
import { MediasSection } from '../../components/sections/program/MediasSection'

export interface ProgramPageProps {
  program: any //ProgramModel
  medias: any //Media[]
  roles: any //Role[]
}

export const Program = ({ program, medias = [], roles = [] }: ProgramPageProps) => {
  const { formatMessage: getI18nKey } = useIntl()
  // const { add, reset } = usePlayerContext()

  // const loadTrailer = useCallback(async () => {
  //   if (program.trailer) {
  //     await add(normalizeToTrack({ ...program, URL: program.trailer }))
  //   }
  // }, [program])

  // useEffect(() => {
  //   loadTrailer()
  //   return () => {
  //     reset()
  //   }
  // }, [program])

  return (
    <div className="max-w-5xl mx-auto p-5">
      {program && <ProgramDescription program={program} />}
      <hr className="border-palombe my-10" />
      {!!medias.length && <MediasSection medias={medias} program={program} />}
      <hr className="border-palombe my-10" />
      {program && <ProgramInformations program={program} medias={medias} roles={roles} />}
    </div>
  )
}
