import { useIntl } from 'react-intl'

import { Icon } from 'src'
import { BlockHigh } from '../../molecules/BlockHigh'

interface ProgramDescriptionProps {
  program: any //BaseProgram
}

export const ProgramDescription = ({ program }: ProgramDescriptionProps) => {
  const { formatMessage: getI18nKey } = useIntl()

  // const handleClickAnalytics = () => {
  //   computeAnalyticsTrackEvent(ANALYTICS_KEYS.PLAY_TRAILER, ANALYTICS_CATEGORIES.PRODUCT_USE, {
  //     name: program.title,
  //   })
  // }

  return (
    <div className="space-y-10">
      <div className="md:flex md:space-x-20">
        <div className="w-60 md:w-1/3 mx-auto pb-5 md:pb-0">
          <BlockHigh data={program} showKeyword={false} showTitle={false} />
        </div>
        <div className="flex-1 text-center md:text-left">
          <hr className="hidden md:block border-heliotrope border-t-2 w-9 pb-1" />
          <p className="text-sm text-white font-normal pb-2 capitalize">{program.keyword1}</p>
          <h1 className="text-white text-2xl font-bold md:pb-8">{program.title}</h1>
          <div className="bg-palombe p-5 hidden md:block rounded-xl space-y-5">
            <h2 className="text-white text-xl font-bold">
              {getI18nKey({
                id: 'programScreen.syllabus.title',
              })}
            </h2>
            <ul>
              {program.syllabus?.map((label, index) => (
                <li className="flex items-center text-cloud text-lg" key={index}>
                  <Icon name="check" className="text-white text-4xl pr-5" />
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div
        className="text-lg font-normal richtext text-center md:text-left"
        dangerouslySetInnerHTML={{ __html: program.description }}
      />
    </div>
  )
}
