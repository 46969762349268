import React from 'react'
import { useIntl } from 'react-intl'
import { groupBy } from 'lodash'
import moment from 'moment'

import { BadgeList } from '../../molecules/BadgeList'
import { Credits } from '../../molecules/Credits'
import { convertDurationMin } from 'src'

export const ProgramInformations = ({ program, medias, roles }: any) => {
  const { formatMessage: getI18nKey } = useIntl()

  const creditsRoles = groupBy(
    Object.values(roles as any)
      .filter(({ type }) => type === 'credit')
      .sort((a: any, b: any) => a.order - b.order),
    ({ kind }) => kind,
  )

  const durationProgram = medias.reduce(
    (accumulator: number, media: any) => accumulator + media.duration,
    0,
  )

  return (
    <div className="md:flex items-center space-x-10">
      <div className="flex-1">
        <h2 className="text-white text-center md:text-left text-xl font-bold pb-10">
          {getI18nKey({ id: 'programScreen.infos.title' })}
        </h2>
        <div className="space-y-3">
          <div className="space-y-2">
            <p className="text-base text-white tracking-wide">
              {getI18nKey({ id: 'programScreen.infos.duration' })}
            </p>
            <p className="text-sm text-cloud">{convertDurationMin(durationProgram)}</p>
          </div>
          <div className="space-y-2">
            <p className="text-base text-white tracking-wide">
              {getI18nKey({ id: 'programScreen.infos.releaseDate' })}
            </p>
            <p className="text-sm text-cloud">{moment.unix(program.publicationDate).year()}</p>
          </div>
          <div className="space-y-2">
            <p className="text-base text-white tracking-wide">
              {getI18nKey({ id: 'programScreen.infos.credits' })}
            </p>
            <Credits credits={creditsRoles} />
          </div>
        </div>
      </div>
      <div className="flex-1 flex-col items-center hidden md:flex">
        <h2>{getI18nKey({ id: 'programScreen.infos.download.title' })}</h2>
        <BadgeList />
      </div>
    </div>
  )
}
